.app {
  width: 100%;

  display: flex;
  flex-direction: row;
  overflow: hidden;
  z-index: 3;
}

.page-content {
  z-index: 3;
}

.sidebar {
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.background {
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(
    185deg,
    hsl(274deg 100% 85%) 0%,
    hsl(273deg 98% 86%) 4%,
    hsl(273deg 95% 87%) 21%,
    hsl(273deg 92% 88%) 46%,
    hsl(273deg 89% 89%) 63%,
    hsl(272deg 85% 90%) 73%,
    hsl(272deg 81% 91%) 81%,
    hsl(272deg 75% 92%) 86%,
    hsl(272deg 69% 92%) 90%,
    hsl(272deg 61% 93%) 93%,
    hsl(272deg 51% 94%) 96%,
    hsl(271deg 38% 95%) 98%,
    hsl(271deg 22% 95%) 99%,
    hsl(0deg 0% 96%) 100%
  );
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  position: fixed;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  z-index: 1;
  width: 110%;
  height: 110%;
  overflow: hidden;
}

.background-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  width: 110%;
  height: 110%;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    width: 100%;
  }

  .footer {
    gap: 0rem;
  }

  .footer > p {
    line-height: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 1.5rem;
  margin: 0 auto;
  height: 100%;
}

h1 {
  font-size: 8rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.subtitle {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  gap: 2rem;
}

.footer > p {
  line-height: 1;
}

.image {
  background-image: url("assets/images/home.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.privacy,
.eula {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  color: black;
  height: auto;
  overflow-y: auto !important;
}

.privacy h1,
.eula h1 {
  font-size: 5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

p.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #3e3e3e !important;
}

a.nav {
  color: white !important;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

button {
  background-color: #b898d2;
  color: white;
  padding: 10px 15px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  border-color: #b898d2;
  font-size: 1.5rem;
  /* border: 1px solid rgba(255, 255, 255, 0.767); */
}

Button > .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0;
  margin: 0;
}
