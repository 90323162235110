body {
  position: relative;
}

.home-root {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.home-content {
  width: 100%;
  height: 100%;
  padding-bottom: 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 1rem;
}

.home-tagline {
  text-align: center;
  font-size: 3rem;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

.home-headline {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.home-subtitle {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.home-mobile-download-ios {
  display: none;
}

@media only screen and (max-device-width: 480px) and (orientation: portrait) {
  .home-mobile-download-ios {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}

.home-legal-modal {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
  background-color: #333333;
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
}

.home-legal-modal-link {
  color: #f5f5f5;
  text-decoration: underline !important;
  cursor: pointer;
}

.home-legal-modal-text {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  margin: 0 0 0.5rem 0;
}

.home-legal-modal-button {
  max-height: 2.5rem;
  font-size: 16px;
  padding: 5px 10px;
}
