.footer {
  position: fixed;
  top: 100vh;
  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #333333;

  width: 100%;
  padding-top: 5rem;
  gap: 2rem;

  padding: 1rem 1.5rem;

  transition: 0.3s ease top;
}

.footer-caret-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: static;
  padding-bottom: 1rem;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.footer-caret {
  left: 50%;
  font-size: 2.5rem;
  color: #f5f5f5;
  z-index: 5;
  font-weight: 300;

  cursor: pointer;
  animation: bounce 2s infinite;
}

.footer-caret.dark {
  color: #333333;
}

.footer.active {
  top: 90vh;
}

.footer > p {
  line-height: 1;
}

.footer > .copyright {
  color: #afafaf;
}

@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 0rem;
    padding: 1rem 0;
  }

  .footer > p {
    line-height: 0;
  }
}
