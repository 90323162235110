.support-content {
  min-height: calc(100% - 10rem);
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;

  width: stretch;
  width: -webkit-fill-available;
  width: -moz-available;
}

.support-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

.support-list .button {
  background-color: #f5f5f5;
  color: #333333;
  box-shadow: 3px 3px 10px 1px #00000010;
}

.content h3 {
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .content .subtitle {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    gap: 0.5rem;
  }

  .support-list {
    flex-direction: column;
    gap: 0rem;
  }

  .content h1 {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 480px) {
  .content {
    gap: 0.5rem;
  }

  .content h1 {
    font-size: 4rem;
  }
}
