.header {
  position: relative;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;

  padding: 1rem 1.5rem;
  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-logo-link {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media only screen and (max-device-width: 480px) and (orientation: portrait) {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .header-download-ios {
    display: none;
  }
}

.header-icon {
  height: auto;
  width: 5rem;
  align-self: start;
}

.header-icon path {
  fill: #fefefe;
}

.header-dark .header-icon path {
  fill: #333333;
}

.header-title {
  font-size: 3rem;
  line-height: 3rem;

  color: #fefefe;
}

.header-dark .header-title {
  color: #333333;
}
