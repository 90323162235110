.redirect {
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;

  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
}

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  margin: 0 auto;
  width: 50%;

  /* background-color: #333333; */
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1.5rem 1.5rem;
  box-shadow: 3px 3px 40px 1px rgba(51, 51, 51, 0.25);

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media only screen and (max-width: 480px) and (orientation: portrait) {
  .container {
    width: 75%;
  }
}

.container button {
  background-color: white;
  color: #333333;
  box-shadow: 1px 1px 8px 1px rgba(51, 51, 51, 0.05);
}

.container .subtitle {
  color: #333333;
  font-size: 24px;
}

.redirect-continue-note {
  color: #333333c0;
  font-style: italic;
}
